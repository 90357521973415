<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="alertModal"
      hide-header
      hide-footer
      centered
      size="sm"
      class="alert-modal"
      no-close-on-backdrop
    >
      <div class="modal-header" v-if="hasClose">
        <button type="button" aria-label="Close" class="close" @click="hide">
          ×
        </button>
      </div>
      <div class="text-center p-3">
        <b-spinner class="align-middle spinner m-3" variant="warning"></b-spinner>
        <p class="mb-0 mt-3">{{ $t("pleaseWait") }}...</p>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      required: false,
      type: String
    },
    hasClose: {
      required: false,
      type: Boolean
    }
  },
  data() {
    return {
      showModal: false
    };
  },
  methods: {
    show() {
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    }
  }
};
</script>

<style scoped>
.modal-header {
  border: 0 !important;
  padding: 0;
}

.img {
  width: 50px;
}

.modal-body {
  margin: auto;
  text-align: center;
}

.spinner {
  width: 3rem;
  height: 3rem;
}
</style>
