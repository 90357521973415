<template>
  <div :class="['div-input', className]">
    <div class="display-only" v-if="isDisplay"></div>
    <div :class="['input-custom', { error: isValidate }]">
      <b-row>
        <b-col :cols="textGuide ? 6 : 12" md="9">
          <label v-if="textFloat" class="text-wrap">
            {{ textFloat }}
            <span v-if="isRequired" class="text-danger">*</span>
          </label>
        </b-col>
        <b-col v-if="textGuide" cols="6" md="3" class="text-right pl-0">
          <span class="text-error mb-0">{{ textGuide }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="8" md="9" class="d-flex align-items-center">
          <input
            :class="['progress']"
            type="range"
            :name="name"
            :id="id"
            :value="value || 0"
            :disabled="disabled"
            :min="min"
            :max="max"
            :step="step"
            @input="handleInput($event.target.value)"
            :style="background"
          />
        </b-col>
        <b-col cols="4" md="3" class="pl-0">
          <input
            :class="['custom-input', 'w-100']"
            :name="name"
            :required="required"
            :value="val"
            size="sm"
            @input="handleInput($event.target.value)"
            @keyup="isNumber"
            @keypress="isNumber"
            :disabled="disabled"
          />
        </b-col>
      </b-row>
    </div>
    <span v-if="detail" class="text-desc text-wrap">{{ detail }}</span>
    <div v-if="v && v.$error" class="text-right">
      <span class="text-error" v-if="v.required == false">{{
        $t("msgValidateRequired")
      }}</span>
      <span class="text-error" v-else-if="v.integer == false">{{
        $t("msgValidateInteger")
      }}</span>

      <span class="text-error" v-else-if="v.decimal == false">{{
        $t("msgValidateInteger")
      }}</span>
      <span class="text-error" v-else-if="v.minValue == false"
        >{{ $t("msgValidateMinLength1") }} {{ v.$params.minValue.min }}
      </span>
      <span class="text-error" v-else-if="v.maxValue == false"
        >{{ $t("msgValidateMaxLength1") }} {{ v.$params.maxValue.max }}
      </span>
      <span class="text-error" v-else-if="v.handleAgeValue == false"
        >ปัจจุบันอายุต้องน้อยกว่าหรือเท่ากับอายุที่คาดว่าจะเกษียณ
      </span>
      <span class="text-error" v-else-if="v.handleExpensePerMonthMin == false"
        >กรุณาระบุค่าใช้จ่ายต่อเดือนของท่าน
      </span>
      <span class="text-error" v-else-if="v.handleExpectRetireAgeMin == false"
        >กรุณาระบุอายุที่คาดว่าจะเกษียณ
      </span>
      <span class="text-error" v-else-if="v.handleExpectRetireAgeValue == false"
        >อายุที่คาดว่าจะเกษียณต้องน้อยกว่าหรือเท่ากับอายุที่อยากใช้เงินหลังเกษียณ
      </span>
      <span class="text-error" v-else-if="v.handleExpectSpendAgeValue == false"
        >ต้องมากกว่าอายุที่คาดว่าจะเกษียณ
      </span>
      <span class="text-error" v-else-if="v.handleExpectSpendAgeMin == false"
        >กรุณาระบุอายุ
      </span>
      <span class="text-error" v-else-if="v.handleFamilyExpenseValue == false"
        >ค่าใช้จ่าย (เฉพาะส่วนของครอบครัว) ต้องน้อยกว่าหรือเท่ากับค่าใช้จ่ายทั้งหมด
      </span>
      <span class="text-error" v-else-if="v.handleDeptValue == false"
        >จำนวนหนี้สินทั้งหมดต้องมากกว่าหรือเท่ากับจำนวนหนี้สินระยะสั้น
      </span>
      <span class="text-error" v-else-if="v.handleShortTermDeptValue == false"
        >จำนวนหนี้สินระยะสั้นต้องน้อยกว่าหรือเท่ากับจำนวนหนี้สินทั้งหมด
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    textFloat: {
      required: true,
      type: String
    },
    required: {
      required: false,
      type: Boolean
    },
    detail: {
      required: false,
      type: String
    },
    name: {
      required: false,
      type: String
    },
    textGuide: {
      required: false,
      type: String
    },
    id: {
      required: false,
      type: [String, Number]
    },
    value: {
      required: false,
      type: [String, Number]
    },
    step: {
      required: false,
      type: [String, Number]
    },
    isDisplay: {
      required: false,
      type: Boolean
    },
    isRequired: {
      required: false,
      type: Boolean
    },
    isValidate: {
      required: false,
      type: Boolean
    },
    v: {
      required: false,
      type: Object
    },
    className: {
      required: false,
      type: String
    },
    disabled: {
      required: false,
      type: Boolean
    },
    min: {
      required: false,
      type: [String, Number]
    },
    max: {
      required: false,
      type: [String, Number]
    }
  },
  computed: {
    val(){
      let value = this.value.toString().replace(/[^\d.-]+/g, '');
      if(value) value = parseFloat(value).toLocaleString();
      
      return value
    },
    background() {
      let min = 0;
      let max = this.max > 5000000 ? 5000000 : this.max
      max = max - this.min;
      let value = this.value - this.min;
      value = (value / max) * 100;
      if(!value) {
        value = 0
        max = 100
      }

      let background = `background:linear-gradient(to right, #F9B526 ${min}%, #FF6439 ${value}%, #F3F3F3 ${value}%, #F3F3F3 100%)`;
      if (this.isDisabled)
        background = `background:linear-gradient(to right, #b7b7b7 ${min}%, #959595 ${value}%, #F3F3F3 ${value}%, #F3F3F3 100%)`;
      return background;
    }
  },
  methods: {
    async handleInput(val) {
      let value = val;
      value = val.toString().replace(/[^\d.-]+/g, '');

      await this.$emit("input", value);
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.div-input {
  margin-bottom: 15px;
  position: relative;
  white-space: nowrap;
}
.input-custom {
  padding: 0px;
  label.text-wrap {
    font-size: 16px;
    color: #707070;
    margin-bottom: 0;
    line-height: 20px;
  }
  .input-custom > input:focus {
    border: 1px solid #f9b526;
  }
  .error > input {
    border-color: red !important;
  }

  .progress {
    background: linear-gradient(
      to right,
      #f9b526 0%,
      #ff6439 0%,
      #f3f3f3 0%,
      #f3f3f3 100%
    );
    border-radius: 8px;
    height: 4px;
    width: 100%;
    outline: none;
    transition: background 450ms ease-in;
    -webkit-appearance: none;
    overflow: unset;
  }

  .progress::-webkit-slider-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    -webkit-appearance: none;
    cursor: ew-resize;
    background: #ff6439;
  }
  .custom-input {
    display: block;
    width: 100%;
    color: #ff6439;
    border: 1px solid #e1e1e1;
    border-radius: 6px;
    padding: 7px;
    font-size: 16px;
    border-radius: 3px;
    text-align: center;
  }
}
.text-desc {
  color: #9b9b9b;
  font-size: 12px;
}
.display-only {
  position: absolute;
  z-index: 5000;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.text-error {
  color: #ff0000;
  font-size: 14px;
  white-space: normal !important;
  width: 100%;
}
.d-grid {
  display: grid;
}
.button-eye {
  position: absolute;
  right: 20px;
  top: 50%;
  z-index: 6;
}
.hastextFloat {
  top: 2.2rem;
}
.text-warning-message {
  color: #bebebe;
  font-size: 12px;
}
@media (max-width: 767.98px) {
  .input-custom label.text-wrap {
    font-size: 15px;
  }
}
</style>
